let mixin = {
  data() {
    return {
      roomsColorArr: [
        "#9C27B0",
        "#E91E63",
        "#F44336",
        "#00C853",
        "#795548",
        "#0D47A1",
        "#00695C",
        "#1B5E20",
        "#FF6D00",
        "#3E2723",
      ],
    };
  },
  computed: {},
  methods: {
  },
};
export default mixin;
